import React, { useState, useEffect, useRef } from 'react';
import { normalizeTime } from '../../../lib/utils/format';

const Timer = ({ elapsedTime = 0, seconds = 900, setIsSubmitDisabled, className = '', timerText,
                children, key }) => {
    const [remainingTime, setRemainingTime] = useState(seconds - elapsedTime);
    const requestRef = useRef();
    const previousTimeRef = useRef();
    
    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    useEffect(() => {
        if (remainingTime < 0) {
            cancelAnimationFrame(requestRef.current);
            setIsSubmitDisabled && setIsSubmitDisabled(true)
        } else {
            setIsSubmitDisabled && setIsSubmitDisabled(false)
        }
    }, [remainingTime])

    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            setRemainingTime(prevTime => (prevTime - deltaTime * 0.001) % 1000);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }
  
    return <div key={key} className={className}>{remainingTime > 0 ? timerText(normalizeTime(Math.round(remainingTime))) : children}</div>
} 

export default Timer;
