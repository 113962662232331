import PropTypes from "prop-types";
import {Input, Label} from "reactstrap";
import {useForm} from "react-hook-form";
import Divider from "../../../../generic/Divider";
import React, {useState, useEffect} from "react";
import { isEmpty } from "lodash";
import MemberCheckoutCard from "./MemberCheckoutCard";
import FormButton from "../../../../generic/buttons/main";
import external from "../../../../../styles/assets/images/sv/external.svg";
import {handleInputChange} from "../../../../../lib/utils/handleInputChange";
import {NumberParam, useQueryParams, withDefault} from "use-query-params";
import HasPaidMessage from "../../../../common/HasPaidMessage";
import PaymentTimer from "../../../../common/PaymentTimer";
import {t} from "i18next";
import withOrderProductExistCheck from "../../../../../lib/utils/hocs/withOrderProductExistCheck";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import PayBlock from '../../../../common/PayBlock';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { PayBlockModelStoreToken } from '../../../../common/PayBlock/models/PayBlockModel/index.model';
import useOrder from './hooks/useOrder';
import useOrderTimeout from './hooks/useOrderTimeout';

const MembersCheckout = observer(({
    order,
    selectedGroup,
    inputMembersData,
    setPromocodeApplied,
    promoAndCertDiscount,
    setCertificateApplied,
    checkOrderProduct,
    setOrder,
    isInstallment,
}) => {
    const payBlockModel = useInjection(PayBlockModelStoreToken);

    const [elapsedTime, setElapsedTime] = useState();
    const [currentData, setCurrentData] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [isGroupOverbooked] = useState(false);

    const [query] = useQueryParams({
        step: withDefault(NumberParam, 1),
        orderId: NumberParam,
    });

    const { submit } = useOrder(checkOrderProduct, currentData, query);
    const { check } = useOrderTimeout(query, setElapsedTime);

    const {register, errors, handleSubmit} = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const handleChange = (e) => {
        const {name, value} = handleInputChange(e);
        setCurrentData((currentData) => ({...currentData, [name]: value}));
    };

    const formButtonNonInstallmentValue = promoAndCertDiscount?.price ? t('common.pay') : t('common.schedule');
    const formButtonValue = isInstallment ? t('common.in_installments') : formButtonNonInstallmentValue;
    const isVisiblePaymentTimer = !isEmpty(order) && !isInstallment && !order?.hasPaid && elapsedTime >= 0;

    useEffect(() => {
        if (query.orderId) {
          check();
        }
    }, [query.orderId]);

    useEffect(() => {
        setCurrentData(order)
    }, [order.id])

    return (
        <>
            <div data-testid='checkout' className={"checkout"}>
                <div className={"checkout__main-info"}>
                    {isVisiblePaymentTimer &&
                        <PaymentTimer elapsedTime={elapsedTime} className="pl-0 pt-1 pr-1"
                                      setIsSubmitDisabled={setIsSubmitDisabled}
                        />
                    }
                    {!isEmpty(order) && isInstallment &&
                        <>{t('common.payment_pending_for_installment')}</>
                    }
                </div>
                <Divider/>
                {inputMembersData?.map((item, index) => <MemberCheckoutCard index={index} member={item} key={index}/>)}
                {!isEmpty(order) && !isInstallment && order.groupId?.price !== 0 && <>
                  <PayBlock
                    productType={PRODUCT_TYPES.GROUPS}
                    order={order}
                    setOrder={setOrder}
                    setCurrentData={setCurrentData}
                    setCertificateApplied={setCertificateApplied}
                    setPromocodeApplied={setPromocodeApplied}
                    isShowSubscription={false}
                    isShowPromocode
                    promoAndCertDiscount={promoAndCertDiscount}
                    productKey={'groupId'}
                  />
                  <Divider/>
                </>}
                <form onSubmit={handleSubmit(submit)}>
                    <div className="checkout__input-wrapper">
                        <div className="title-label">{t('inputs.comment.label')}</div>
                        <Input type="textarea" id="comment" name="comment" onChange={handleChange}
                               innerRef={register({required: false, maxLength: 300})} defaultValue={currentData.comment}
                               placeholder={t('inputs.comment.placeholder')}
                        />
                        <span className={errors.comment ? "error-label" : "error-label d-none"}>
                            {errors.comment?.type === "required" ? t('inputs.required') : ""}
                            {errors.comment?.type === "maxLength"
                                && t('inputs.comment.maxLength')}
                        </span>
                    </div>
                    <Divider/>
                    <div className="checkout__input-wrapper">
                        <Input type="checkbox" id="validDataConsent" defaultChecked={currentData.validDataConsent}
                               name="validDataConsent" onChange={handleChange} innerRef={register({required: true})}
                        />
                        <Label htmlFor="validDataConsent" className="offer-label">
                            <a href={t('agreements.personalData')}
                               target="_blank" rel="noopener noreferrer">
                                {t('inputs.consents.validData')}
                                <img src={external} alt=""/>
                            </a>
                        </Label>
                        <span className={errors.validDataConsent ? "error-label" : "error-label d-none"}>
                            {errors.validDataConsent?.type === "required"
                                ? t('inputs.consents.validDataError')
                                : ""}
                        </span>
                    </div>
                    <div className="checkout__input-wrapper">
                        <Input type="checkbox" id="publicOfferConsent"
                               defaultChecked={currentData.publicOfferConsent}
                               name="publicOfferConsent" onChange={handleChange} innerRef={register({required: true})}
                        />
                        <Label htmlFor="publicOfferConsent" className="offer-label">
                            <a href={selectedGroup.publicContractUrl} target="_blank" rel="noopener noreferrer">
                                {t('inputs.consents.publicOffer')}
                                <img src={external} alt=""/>
                            </a>
                        </Label>
                        <div className={"note-label"}> {t("inputs.consents.publicOfferNote")}</div>

                        <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
                            {errors.publicOfferConsent?.type === "required"
                                ? t('inputs.consents.publicOfferError')
                                : ""}
                        </span>
                    </div>
                    {isGroupOverbooked &&
                        <p className="member">{t('errors.someoneAlready')}</p>
                    }
                    <FormButton value={formButtonValue}
                                type="submit" disabled={isSubmitDisabled || isGroupOverbooked || order?.hasPaid}/>
                    {order?.hasPaid ? <HasPaidMessage /> :
                      order.groupId?.price !== 0 && payBlockModel.isCardPay() && <div className='text-center h6 mt-4'>
                        {t('common.layout.payExtraCardDesc')}
                      </div>}
                </form>
            </div>
        </>
    );
});

MembersCheckout.propTypes = {
    order: PropTypes.object,
    selectedGroup: PropTypes.object,
    inputMembersData: PropTypes.array,
    promocodeApplied: PropTypes.object,
    setPromocodeApplied: PropTypes.func,
    certificateApplied: PropTypes.object,
    setCertificateApplied: PropTypes.func,
    promoAndCertDiscount: PropTypes.object,
};

export default withOrderProductExistCheck(MembersCheckout);

