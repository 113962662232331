import React, {useEffect, useMemo, useState} from 'react';
import {Row, Button} from 'reactstrap';
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import './style.scss'
import {pluralizeWord} from "../../../lib/utils/helpers";
import i18next from "i18next";

type CounterProps = {
    limit?: number,
    outerClassName?: string,
    stepSize?: number,
    pluralizer?: (alterCapacity: number) => string,
    onChange?: () => void
}

const equalizeCapacity = (peopleAmount: number, alterCapacity: number) => Math.ceil(peopleAmount / alterCapacity)

const Counter = ({limit, outerClassName, stepSize, pluralizer, onChange}: CounterProps) => {
    const [peopleCount, setPeopleAmount] = useQueryParamWithDefault<number>('people', NumberParam, 1)
    const [alterCapacityCount, setAlterCapacityCount] =
            useState<number>(stepSize ? equalizeCapacity(peopleCount, stepSize) : 1);

    const counterStep = useMemo(() => stepSize || 1, [stepSize]);
    const updateAlterCapacity = (amount: number) =>
            stepSize && setAlterCapacityCount(capacity => capacity + amount);

    const decreasePeopleCount = () => {
        const decreasedPeople = peopleCount - counterStep;
        if (decreasedPeople > 0) {
            updateAlterCapacity(-1);
            setPeopleAmount(decreasedPeople);
        }
        if (decreasedPeople <= 0) {
            setPeopleAmount(1);
            updateAlterCapacity(-1);
        }

        onChange && onChange();
    }

    const increasePeopleCount = () => {
        const increasedPeople = peopleCount + counterStep;
        if (!limit || (limit && (increasedPeople <= limit))) {
            setPeopleAmount(peopleCount + counterStep);
            updateAlterCapacity(1);
        }

        onChange && onChange();
    }

    const {t, language} = i18next;

    useEffect(() => {
        if (limit && (peopleCount > limit)) setPeopleAmount(limit);
        if (peopleCount <= 0) setPeopleAmount(1);
        if (stepSize) {
            const alterCapacity = equalizeCapacity(peopleCount, stepSize);
            setAlterCapacityCount(alterCapacity)
            setPeopleAmount(alterCapacity * stepSize)
        }
    }, [limit, stepSize])

    return (
            <Row className={`counter ${outerClassName} no-gutters mb-4`} role="counter">
                <Button data-testid='counter__btn-decrease' onClick={decreasePeopleCount} disabled={stepSize ? alterCapacityCount < 2 : peopleCount < 2} className={'counter__btn decrease'}/>
                {
                    stepSize
                            ? <div className="alter-capacity-container">
                                <div>{alterCapacityCount} <span>{pluralizer?.(alterCapacityCount)}</span></div>
                                <div data-testid='people-count-block' className="alter-capacity-inner">{peopleCount} {pluralizeWord(t('common.pluralizePlaces'), peopleCount, language)}</div>
                              </div>
                            : <div data-testid='people-count-block'>{peopleCount} <span>
                                {language === 'ru' && (peopleCount > 1 && peopleCount < 5 ? 'человека' : 'человек')}
                                {language === 'en' && (peopleCount === 1 ? 'person' : 'people')}
                            </span></div>

                }
                <Button data-testid='counter__btn-increase' onClick={increasePeopleCount} disabled={limit ? (peopleCount >= limit) : false}
                        className={'counter__btn increase'}/>
            </Row>
    )
}

export default Counter;

