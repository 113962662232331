import {IDifficultyLevel} from "../../../lib/utils/types";
import {t} from "i18next";

const DifficultyLevel = ({ difficulty }: IDifficultyLevel) => (
    <div className={`difficulty difficulty-${difficulty.level}`}>
        <div className="difficulty__label card-label">{t('bookingCard.difficultyLevel')}</div>
        <div className="difficulty__caption card-caption">
            {difficulty.title}
        </div>
        <div className="difficulty__graph">
            <div className="difficulty-divider"></div>
            <div className="difficulty-divider"></div>
            <div className="difficulty-divider"></div>
        </div>
        <div className="difficulty__description">
            {difficulty.description}
        </div>
    </div>
)

export default DifficultyLevel